var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3" }, [
          _c("div", { staticClass: "card" }, [
            _c("h4", { staticClass: "card-title mb-4" }, [
              _vm._v("Informations Générales")
            ]),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-nowarp mb-0" }, [
                _c("tr", [
                  _c("th", [_vm._v("Nom de la Base")]),
                  _c("td", [_vm._v("{{}}")])
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Email")]),
                  _c("td", [_vm._v("{{}}")])
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Télephone")]),
                  _c("td", [_vm._v("{{}}")])
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Wilaya")]),
                  _c("td", [_vm._v("{{}}")])
                ]),
                _c("tr", [
                  _c("th", [_vm._v("Addresse")]),
                  _c("td", [_vm._v("{{}}")])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }